import *  as firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAPKvX2nftgMKVyCwxq3KYOuH7VCWlMOTc",
    authDomain: "bingo-15558.firebaseapp.com",
    databaseURL: "https://bingo-15558.firebaseio.com",
    projectId: "bingo-15558",
    storageBucket: "bingo-15558.appspot.com",
    messagingSenderId: "389145663885",
    appId: "1:389145663885:web:964b17efa7a443effb85c4"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;