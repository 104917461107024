import React from 'react';
import './App.css';

import CardProvider from './hooks/cardprovider';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Form from './hooks/form';
import Search from './hooks/search';
import Card from './hooks/card';
import Error from './hooks/error';

function App() {

    return (
      <div>
        <CardProvider>
        
        <BrowserRouter >
        <Search/>
          <Switch>
            <Route path='/error/:id' component={Error} />
            <Route exact path="/" component={Form} />
            <Route path="/:id" component={Card} />
            

          </Switch>         
        </BrowserRouter>
        </CardProvider>
      </div>
    );

  
}

export default App;
