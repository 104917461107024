import React from 'react';
import {useParams} from 'react-router-dom';

const Error = () => {
    const params = useParams();

    return(
        <div>Board: {params.id} does not exist.</div>
    )
}
export default Error;