import React, {useContext, useState} from 'react';
import {Redirect} from 'react-router-dom';
import CardContext from './cardcontext';
import validate from '../utils/validation';

import firebase from '../utils/firebase';

import '../styles/form.css';


const cardForm = {
    title: {'key': "", 'value': false},
    tiles: Array(24).fill(null).map(() => ({'key': "", 'value': false}))
};

const Form = () => {
    
    const [form, setForm] = useState(cardForm);
    const [redirectPage, setRedirectPage] = useState(false);
    const [path, setPath] = useState("");
    const bingo = useContext(CardContext);

    

    const addTile = () => {
        if(form.tiles.length < 75){
            let prevTiles = form.tiles;
            setForm({...form, tiles: prevTiles.concat({'key': "", 'value': false})});
        }       
    }

    const removeTile = (index, evt) => {
        evt.preventDefault();
        let prevTiles = form.tiles;
        setForm({...form, tiles: prevTiles.filter((_, i) => i !== index)});
    }

    const handleTileChange = (index, evt) => {
        evt.preventDefault();

        let prevTile = form.tiles.slice();
        prevTile[index].key = evt.target.value;
        setForm({...form, tiles: prevTile});
    }

    const handleTitleChange = (evt) => {
        evt.preventDefault();
        let prevTitle = form.title;
        prevTitle.key = evt.target.value;
        setForm({...form, title: prevTitle});
    }

    const handleCardChange = (title, tiles) => {
        let prevTitle = form.title;
        let prevTiles = form.tiles;

        prevTitle = title;
        prevTiles = tiles;

        setForm({title: prevTitle, tiles: prevTiles});
    }

    const handleBackSpace = (index, evt) =>{
        if((evt.keyCode === 8) && (evt.target.value === "") && (index >= 24)){
            removeTile(index, evt);
        }
    }
    const clearState = () => {
        cardForm.title = {'key': "", 'value': false};
        cardForm.tiles = Array(24).fill(null).map(() => ({'key': "", 'value': false}))
    }


    const handleSubmit = () => {


        const cardInfo = validate(form);

        if(cardInfo.titleContainsTrue === true || cardInfo.tilesContainsTrue === true){
            handleCardChange(cardInfo.title, cardInfo.tiles);
        }
        else{                
            
            const title = cardInfo.title.key.toString();
            const tiles = cardInfo.tiles.map(index => index.key.toString());
            const invitation = cardInfo.invitation;
            const timestamp = cardInfo.timestamp;

            bingo.handleBingoBoard(title, tiles, invitation, timestamp);

            const boardRef = firebase.firestore().collection('boards').doc(invitation);
            boardRef.set({
                title: title,
                tiles:  tiles,
                timestamp: timestamp
            }).catch(function (error){
                console.error('Error adding collection: ', error);
            })

            setPath("/"+ invitation);
            setRedirectPage(true);

            clearState();          
        }        
    }

    return(
        <div>
            {redirectPage ? <Redirect to={path}/> :
            <div className="form-container">

                <div className="title-container">
                    <input
                        className={form.title.value ? "form-title-error" : "form-title"}
                        value={form.title.key} 
                        maxLength="40"
                        spellCheck="false"
                        placeholder="Title"
                        onChange={(e) => handleTitleChange(e)} 
                    />
                </div>
                
                <div className="tile-section">
                    {
                        form.tiles.map((tile, index) =>{
                            return <div className="tile-container" key={index.toString()} >
                                        <div className={form.tiles[index].value ? "form-tile-label-error" : "form-tile-label"}>{(index+1).toString()}.</div>
                                        <input
                                            className={form.tiles[index].value ? "form-tile-error" : "form-tile"}                                        
                                            value={tile.key} 
                                            maxLength="35"
                                            spellCheck="false"
                                            onChange={(e) => handleTileChange(index, e)} 
                                            onKeyDown={(e) => handleBackSpace(index, e)}
                                        />
                                    </div>
                        })
                    }
                </div>
                <div className="form-button-container">
                    <button className="form-button" onClick={addTile}>Add Tile</button>
                    <button className="form-button" onClick={handleSubmit}>Submit Board</button>
                </div>
        
            </div> 
             }
        </div>
    )
}
export default Form;