const status = {NEITHER: "NEITHER", BLACKOUT: "BLACKOUT", BINGO: "BINGO"};
//
const winCon = [
                    [0,1,2,3,4],[5,6,7,8,9],[10,11,12,13],[14,15,16,17,18],[19,20,21,22,23],
                    [0,5,10,14,19],[1,6,11,15,20],[2,7,16,21],[3,8,12,17,22],[4,9,13,18,23],
                    [0,6,17,23],[4,8,15,19]
                ];

function checkStatus(selected){
    let hasBingo = false;
    if(!selected.includes(false)){
        return status.BLACKOUT;
    }
    else{
        for(var i = 0; i < winCon.length; i++){
    
            for(var j = 0; j < winCon[i].length; j++ ){
                if(selected[winCon[i][j]] === false){
                    hasBingo = false;
                    break;
                }
                else{
                    hasBingo = true;
                }                
            }
            if(hasBingo){
                return status.BINGO;
            }
            
        }  
        return status.NEITHER;
    }
    
}
export default checkStatus