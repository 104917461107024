import React, { useState, useContext} from 'react';
import firebase from '../utils/firebase';
import CardContext from './cardcontext';
import {useHistory} from 'react-router-dom';


import '../styles/search.css';
import home from '../images/logo.png';

const Search = () => {
    const [query, setQuery] = useState("");
    const board = useContext(CardContext);
    const history = useHistory();

    const handlePageToForm = () => {
        setQuery("");
        history.push("/");
        window.location.reload();
    }
    const handleEnterKey = (evt) =>{

        if(evt.keyCode === 13){
            handleSubmit(evt);
        }
    }
    const handleSubmit = (evt) =>{
        evt.preventDefault();
        setQuery("");
        if(query.length > 0){
            const boardRef = firebase.firestore().collection('boards').doc(query.toString());
            boardRef.get().then(collection => {
            if(collection.exists){
                 board.handleBingoBoard(collection.data().title, collection.data().tiles, query, collection.data().timestamp)
                const path = "/"+query
                history.push(path);
            }
            else{
                
                return history.push("/error/"+ query);
            }
            }).catch(function (error){
                console.error('Error getting collection', error);
            })
        }        
    }


    return(
        <div className="header">
            <div className="label-container">
              <img src={home} onClick={handlePageToForm} alt=""/> 
            </div>
            <div className="search-container">
                <input
                    className= "search-input"
                    value={query} 
                    maxLength="7"
                    spellCheck="false"
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => handleEnterKey(e)}
                />
                <button className="search-button" onClick={handleSubmit}>Search</button>
            </div>
        </div>
        
    )
}
export default Search;