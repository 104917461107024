import React, {useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import BingoBoard from './bingoboard';
import CardContext from './cardcontext';
import firebase from '../utils/firebase';



const Card = () =>{
    const upload = useContext(CardContext);
    const params = useParams();
    const history = useHistory();
 
    const handleRoute = () =>{
        if(upload){ 
           const boardRef = firebase.firestore().collection('boards').doc(params.id);
           
           boardRef.get().then(collection => {
               if(!collection.exists){
                  return history.push("/error/"+params.id);
               }
               else{
                   upload.handleBingoBoard(collection.data().title, collection.data().tiles, params.id, collection.data().timestamp);
                   return <BingoBoard data={upload}/>
               }
           }).catch(function (error){
               console.error('Error getting collection', error);
           })

        }
    }

    return(
              
        <div>{upload.bingoBoard === null  ? handleRoute(): <BingoBoard data={upload}/>   } </div>    
    )

}

export default Card;