import React, {useEffect, useState, useRef} from 'react';
import checkStatus from '../utils/checkstatus';

import '../styles/card.css';

const selectedTiles = Array(24).fill(false);




const BingoBoard = ({data}) => {

    const node = useRef();

    const [selected, setSelected] = useState(selectedTiles);
    const [gameStatus, setGameStatus] = useState("NEITHER");
    const [open, setOpen] = useState(false);    

    const tiles = data.bingoBoard.tiles;

    const handleSelected = (evt, index) => {
        const prevSelected = selected;
        prevSelected[index] = selected[index] === false ? true :  false;
        
        setSelected([...prevSelected]);

        const prev = gameStatus;        
        const status = checkStatus(selected);
        
        if((status === "BINGO" && prev === "NEITHER") || status === "BLACKOUT"){
            setOpen(true);
        }
        else{
            setOpen(false);
        }
        setGameStatus(status);
    }

    const handleShuffle = (evt) => {

        evt.preventDefault();

        setSelected(s => s.map(() => false));

        data.handleTileShuffle(tiles);          
    }
   
    
    const handleClickOutside = (evt) =>{
        if(node.current.contains(evt.target)){
           return; 
        }
        setOpen(false);
    }

    useEffect(() => {
        if(open){
            document.addEventListener("mousedown", handleClickOutside);
        }
        else{
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);



    return(
    
        <div>
            {open ?  <div className="overlay"><div ref={node} className="card-gs-message">{gameStatus.split("").map((char, index) => {return <span key={index}>{char}</span>})}</div></div>: <div style={{display: "none"}}></div>}
            <div className="bingo-card">
                <div className="title-container">{data.bingoBoard.title}</div>
                <div className="tt-container">
                    <div className="bingo-label">
                        <div className="bingo-label-item">B</div>
                        <div className="bingo-label-item">I</div>
                        <div className="bingo-label-item">N</div>
                        <div className="bingo-label-item">G</div>
                        <div className="bingo-label-item">O</div>
                    </div>
                    <div className="tiles-container">
                        {
                            
                            tiles.slice(0,24).map((tile, index) => {
                                if(index === 12){
                                    
                                    return( 
                                        <React.Fragment key="fragement">
                                            <div key="free" className="free-space"> FREE </div>
                                            <div style={{ fontSize: `${1+((75-(tile.toString().length))*(24))/(74)}px`, backgroundColor: selected[index] ? '#ff8c00' : ''}} key={index.toString()} className="tile-item"  onClick={(e) => handleSelected(e, index)}>
                                                {tile.toString()}
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                                return <div key={index.toString()} style={{ fontSize: `${1+((75-(tile.toString().length))*(24))/(74)}px`, backgroundColor: selected[index] ? '#ff8c00' : ''}}  className="tile-item"  onClick={(e) => handleSelected(e, index)}>{tile.toString()}</div>
                            
                            })           
                        }
                    </div>
                </div>
                <div className="status-bar">
                    <div>Invite: {data.bingoBoard.invitation}</div>
                    <div></div>
                    <button className="card-button" onClick={handleShuffle}>shuffle</button>
                </div>            
            </div>
        </div>
    )
}
export default BingoBoard;