import React, {useState} from 'react';
import CardContext from './cardcontext';

const bingo = null;


const CardProvider =(props) =>{

    const [bingoBoard, setBingoBoard] = useState(bingo);

    const shuffle = (tiles)  => {
        let shuffledTiles = tiles.map((a) =>
            [Math.random(), a]).sort((a,b) =>
            a[0]-b[0]).map((a) => a[1]);
    
        return shuffledTiles
    }
    
    const handleTileShuffle = (tiles) => {
        const rnd = shuffle(tiles);
        setBingoBoard({...bingoBoard,
            tiles: rnd
        });
    }
    
    const handleBingoBoard = (title, tiles, invitation, timestamp) => {
        const rnd = shuffle(tiles)
        setBingoBoard({...bingoBoard,
            title: title,
            tiles: rnd, 
            invitation: invitation, 
            timestamp: timestamp
        })

    }

    return(

        <CardContext.Provider value={{bingoBoard, handleBingoBoard, handleTileShuffle}}>
           {props.children}
        </CardContext.Provider>

    )
}

export default CardProvider;