//combine both custom filters
function customTilesFilter(input){
    input.forEach((index) => {
        let textInput = index.key.trim().replace(/\s\s+/g, ' ');

        if((textInput === "" || textInput.match(/^ *$/) !== null || textInput.length > 35)){
            index.value = true;
        }

        else if(index.value === true){
            index.value = false;
        }

        index.key = textInput;
    })

    return input;
}

function customTitleFilter(input) {
    let textTitle = input.key.trim().replace(/\s\s+/g, ' ');

    if((textTitle === "" || textTitle.match(/^ *$/) !== null || textTitle.length > 40)){
        input.value = true;
    }
    else if(input.value === true){
        input.value = false;
    }

    input.key = textTitle;

    return input;
}

function checkTilesContainTrue(tiles){
    let containsTrue = false;

    tiles.forEach((index) => {
        if(index.value === true){
            containsTrue = true;
        }
    })

    return containsTrue;
}

const validate = values => {
    let cardValidation = [];

    //console.log(values.data.title);
    let filteredTitle = customTitleFilter(values.title);
    let filteredTiles = customTilesFilter(values.tiles);

    let titleContainsTrue = filteredTitle.value;
    let tilesContainsTrue = checkTilesContainTrue(filteredTiles);

    cardValidation.title = filteredTitle;
    cardValidation.tiles = filteredTiles;
    cardValidation.titleContainsTrue = titleContainsTrue;
    cardValidation.tilesContainsTrue = tilesContainsTrue;
    cardValidation.invitation = Math.random().toString(36).slice(2,9);
    cardValidation.timestamp = Date.now().toString();

    return cardValidation;
}

export default validate;